import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FaWhatsappSquare } from "react-icons/fa"

const getData = graphql`
  {
    info: site {
      siteMetadata {
        contact
      }
    }
  }
`

export default function stickybottom() {
  return (
    <StaticQuery
      query={getData}
      render={data => {
        const contact = data.info.siteMetadata.contact
        return (
          <nav className="navbar fixed-bottom navbar-light bg-warning">
            <div className="d-flex">
              <div>
                <a className="navbar-brand" href={contact}>
                  <FaWhatsappSquare className="icon-whatsapp" />
                </a>
              </div>
              <div className="pt-1">
                <a className="navbar-brand" href="https://www.mega888cafe.com">
                  <h6>
                    <a
                      href={contact}
                      alt="XE88 helpdesk"
                      className="text-dark"
                    >
                      How can we help you? Contact Us Now!
                    </a>
                  </h6>
                </a>
              </div>
            </div>
          </nav>
        )
      }}
    />
  )
}
